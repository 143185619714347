import React, { useState, useEffect } from 'react';
import './App.css'; // 引入CSS文件

const initialData = [
    { id: 202, amount: 15556.32, include: false, paid: false },
    { id: 301, amount: 30000.36, include: true, paid: false },
    { id: 401, amount: 44444.4, include: true, paid: false },
    { id: 402, amount: 44444.4, include: true, paid: false },
    { id: 403, amount: 44444.4, include: true, paid: false },
    { id: 501, amount: 58889.22, include: true, paid: false },
    { id: 502, amount: 58889.22, include: true, paid: false },
    { id: 601, amount: 73333.26, include: true, paid: false },
    { id: 602, amount: 73333.26, include: true, paid: false },
    { id: 603, amount: 73333.26, include: true, paid: false },
    { id: 701, amount: 87777.3, include: true, paid: false },
    { id: 702, amount: 87777.3, include: true, paid: false },
    { id: 703, amount: 87777.3, include: true, paid: false },
];

const App = () => {
    const [rows, setRows] = useState(initialData);
    const [inputAmount, setInputAmount] = useState(() => {
        return localStorage.getItem('inputAmount') || 0;
    });

    useEffect(() => {
        const storedRows = localStorage.getItem('rows');
        if (storedRows) {
            setRows(JSON.parse(storedRows));
        }
    }, []);

    const handleCheckboxChange = (id, field) => {
        const updatedRows = rows.map(row => row.id === id ? { ...row, [field]: !row[field] } : row);
        setRows(updatedRows);
        localStorage.setItem('rows', JSON.stringify(updatedRows));
    };

    const handleAmountChange = (e) => {
        const amount = e.target.value;
        setInputAmount(amount);
        localStorage.setItem('inputAmount', amount);
    };

    const calculate = () => {
        const totalAmount = rows.filter(row => row.include).reduce((sum, row) => sum + row.amount, 0);
        const updatedRows = rows.map(row => {
            if (row.include) {
                const shareRatio = row.amount / totalAmount;
                const shareAmount = inputAmount * shareRatio;
                return { ...row, shareRatio: (shareRatio * 100).toFixed(2) + '%', shareAmount: ceilToOneDecimalPlace(shareAmount.toFixed(3)), paid:false};
            } else {
                return { ...row, shareRatio: '0%', shareAmount: '0.00', paid:false};
            }
        });
        setRows(updatedRows);
        localStorage.setItem('rows', JSON.stringify(updatedRows));
    };
    function ceilToOneDecimalPlace(num) {
        return Math.ceil(num * 10) / 10;
    }
    return (
        <div>
            <div>
                57弄9号电梯电费计算器<br/>
                <input type="number" value={inputAmount} onChange={handleAmountChange} placeholder="金额" />
                <button onClick={calculate}>计算</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>是否计入</th>
                        <th>室号</th>
                        <th>出资额</th>
                        <th>分摊比例</th>
                        <th>分摊缴费金额</th>
                        <th>已缴费</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id}>
                            <td>
                                <input type="checkbox" checked={row.include} onChange={() => handleCheckboxChange(row.id, 'include')} />
                            </td>
                            <td>{row.id}</td>
                            <td>{row.amount.toFixed(2)}</td>
                            <td>{row.shareRatio || '0%'}</td>
                            <td className="share-amount">{row.shareAmount || '0.00'}</td>
                            <td>
                                <input type="checkbox" checked={row.paid} onChange={() => handleCheckboxChange(row.id, 'paid')} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>操作说明：<br/>【1】按找电费账单填写金额<br/> 【2】勾选要加入收费用户<br/> 【3】点击“计算”按钮，得到分摊金额，按此收费<br/>(收款员确认后，将本页的手机截图，发到群里通知各用户收款)<br/> 【4】收费员勾选“已缴费”辅助记忆</div>
        </div>
    );
};

export default App;
